'use client'

import { useState } from 'react'
import styles from './login.module.sass'

import { useRouter, useSearchParams } from 'next/navigation'

import { useSerenityForm } from 'serenity-controls'

import { FormValidationErrors } from '@/common/typings/FormValidationResult'
import { authApiLoginFetcher } from 'common-shared'
import Image from 'next/image'

const LoginPage = () => {
    const { register, ...form } = useSerenityForm<{ login: string; password: string }>()

    const [loading, setLoading] = useState<boolean>(false)
    const router = useRouter()
    const searchParams = useSearchParams()
    const [errors, setErrors] = useState<FormValidationErrors>([])

    const loginHandler = async () => {
        setLoading(true)
        const res = await authApiLoginFetcher(form.getValues())

        setLoading(false)

        if (typeof res !== 'boolean') {
            setErrors(res)
        }
        if (res === true) {
            if (searchParams?.get('returnUrl')) {
                router.push((searchParams?.get('returnUrl') as string).replace('/next', ''))
            } else {
                router.push('/pl')
            }
        }
    }

    return (
        <div className={styles.pageContainer}>
            <Image src="/next/logo.png" alt="Logo" className={styles.logo} width={186} height={75} />

            <div className={styles.loginContainer}>
                <form className="form">
                    <div className={styles.fieldContainer}>
                        <div>
                            <div className="input ">
                                <input type="text" autoFocus={true} {...register('login')} name={'login'} placeholder={'Podaj swój login'} />
                                {errors
                                    .filter((entry) => entry.field === 'login')
                                    .map((entry) => (
                                        <div key={entry.msg} className={styles.fieldError}>
                                            {entry.msg}
                                        </div>
                                    ))}
                            </div>
                            <div className="input">
                                <input type="password" {...register('password')} placeholder={'Podaj hasło'} />
                                {errors
                                    .filter((entry) => entry.field === 'password' || entry.field === null)
                                    .map((entry) => (
                                        <div key={entry.msg} className={styles.fieldError}>
                                            {entry.msg}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="button">
                            <button
                                className="login-button btn btn-primary "
                                disabled={loading}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (!loading) {
                                        loginHandler()
                                    }
                                }}
                            >
                                {'zaloguj się'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage
